<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04">
          <img
            src="@/assets/front_component/images/assets/back02.png"
            alt="雲"
          />
        </div>
      </div>
      <div class="left_area">
        <div class="img01">
          <img
            src="@/assets/front_component/images/assets/back01.png"
            alt="雲"
          />
        </div>
      </div>
    </div>
    <div class="en_ttl en">
      <span>{{ messagetype.title_en }}</span>
    </div>
    <div class="content_wrap">
      <h2>{{ messagetype.title_jp }}</h2>
      <div class="mascot_wrap">
        <img
          src="@/assets/front_component/images/assets/meguru05.png"
          alt="めぐる君"
        />
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap m_b30" v-if="message_type == 'column'">
        <div class="tab_wrap">
          <ul class="tab_list">
            <li class="yakuin active" @click="ColumnListChange('all')">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff08_out.png"
                  alt="すべてのコラム"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff08_over.png"
                  alt="すべてのコラム"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff08_active.png"
                  alt="すべてのコラム"
                  class="mouse_active"
                />
              </div>
              <h3>すべてのコラム</h3>
            </li>
            <li class="toukatu" @click="ColumnListChange('ceo')">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff09_out.png"
                  alt="社長コラム"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff09_over.png"
                  alt="社長コラム"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff09_active.png"
                  alt="社長コラム"
                  class="mouse_active"
                />
              </div>
              <h3>社長コラム</h3>
            </li>
            <li class="eigyo" @click="ColumnListChange('officer')">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff10_out.png"
                  alt="役員コラム"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff10_over.png"
                  alt="役員コラム"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff10_active.png"
                  alt="役員コラム"
                  class="mouse_active"
                />
              </div>
              <h3>役員コラム</h3>
            </li>
            <li class="control" @click="ColumnListChange('woker')">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff11_out.png"
                  alt="社員コラム"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff11_over.png"
                  alt="社員コラム"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff11_active.png"
                  alt="社員コラム"
                  class="mouse_active"
                />
              </div>
              <h3>社員コラム</h3>
            </li>
          </ul>
        </div>
      </div>

      <div class="content_in_wrap m_b30" v-if="message_type == 'daily'">
        <div class="tab_wrap">
          <ul class="tab_list">
            <li class="yakuin active" @click="DailyListChange('all')">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff08_out.png"
                  alt="すべての気づき日報"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff08_over.png"
                  alt="すべての気づき日報"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff08_active.png"
                  alt="すべての気づき日報"
                  class="mouse_active"
                />
              </div>
              <h3>すべての気づき日報</h3>
            </li>
            <li class="toukatu" @click="DailyListChange('改善')">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff09_out.png"
                  alt="改善"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff09_over.png"
                  alt="改善"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff09_active.png"
                  alt="改善"
                  class="mouse_active"
                />
              </div>
              <h3>改善</h3>
            </li>
            <li class="eigyo" @click="DailyListChange('コミュニケーション')">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff10_out.png"
                  alt="コミュニケーション"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff10_over.png"
                  alt="コミュニケーション"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff10_active.png"
                  alt="コミュニケーション"
                  class="mouse_active"
                />
              </div>
              <h3>コミュニケーション</h3>
            </li>
            <li class="control" @click="DailyListChange('他部署報告')">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff11_out.png"
                  alt="他部署報告"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff11_over.png"
                  alt="他部署報告"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff11_active.png"
                  alt="他部署報告"
                  class="mouse_active"
                />
              </div>
              <h3>他部署報告</h3>
            </li>
            <li class="control" @click="DailyListChange('お客様の声')">
              <div class="icon_wrap">
                <img
                  src="@/assets/front_component/images/assets/staff03_out.png"
                  alt="お客様の声"
                  class="mouse_out"
                />
                <img
                  src="@/assets/front_component/images/assets/staff03_over.png"
                  alt="お客様の声"
                  class="mouse_over"
                />
                <img
                  src="@/assets/front_component/images/assets/staff03_active.png"
                  alt="お客様の声"
                  class="mouse_active"
                />
              </div>
              <h3>お客様の声</h3>
            </li>
          </ul>
        </div>
      </div>

      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li>
              <router-link :to="{ name: 'Front top' }">ホーム</router-link>
            </li>
            <li>
              <span>{{ messagetype.title_jp }}一覧 </span>
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div v-if="message_type == 'award'">
              <div class="form_outwrap">
                <div class="wrap">
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <select v-on:change="onPeriodChange">
                        <option value="すべての表彰">すべての表彰</option>
                        <option value="今月の表彰">今月の表彰</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="message_type == 'daily'">
              <div class="form_outwrap">
                <div class="wrap">
                  <div class="in_wrap">
                    <div class="select_wrap">
                      <select
                        v-on:change="onDepartmentChange"
                        v-if="daily_group_list.length != 0"
                        id="departmentSelect"
                      >
                        <option value="すべての部署">すべての部署</option>
                        <option
                          v-for="group in daily_group_list"
                          :key="group.id"
                          :value="group.id"
                        >
                          {{ group.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="board_wrap">
              <ul class="news_list board" v-if="messages.length != 0">
                <li v-for="message in messages" :key="message.id">
                  
                  <a
                    @click="setReadAt(message)"
                    style="cursor: pointer"
                    v-if="message.read_at == null && message_type == 'daily'"
                    class="notifications"
                  >
                    <div class="icon_wrap">
                      <img
                        :src="iconPath"
                        :alt="messagetype.title_jp"
                        class="mouse_out"
                      />
                      <img
                        :src="iconPath02"
                        :alt="messagetype.title_jp"
                        class="mouse_over"
                      />
                    </div>

                    <h3 v-if="message_type == 'thanks'" class="txt">
                      <span
                        v-for="userlist in message.target_user"
                        :key="userlist.id"
                        >{{ userlist.last_name }}さん</span
                      >
                      <span>への{{ message.title }}</span>
                    </h3>

                    <h3 v-else-if="message.type == 'idea'" class="txt">
                      {{ message.content }}
                    </h3>

                    <h3
                      v-else-if="
                        message.type == 'challenge' || message.type == 'sdgs'
                      "
                      class="txt"
                    >
                      <!-- {{ message.option }} -->
                      {{ showYear(message.option) }}
                    </h3>

                    <div
                      v-else-if="message.type == 'daily'"
                      class="target_in_ttl_wrap"
                    >
                      <h3 class="txt">{{ message.content }}</h3>
                      <div
                        v-if="message.target_user != ''"
                        class="target_user_wrap"
                      >
                        <span>宛先：</span>
                        <img
                          v-if="message.target_user.length <= 0"
                          src="https://www.kdfc.com/wp-content/themes/uscradio/dist/images/spinner-1.gif"
                          width="25"
                          height="25"
                          style="margin-right: 10px"
                        />
                        <span
                          class="target_user_name"
                          v-for="user in message.target_user"
                          :key="user.id"
                          >{{ user.last_name + " " + user.first_name }}</span
                        >
                      </div>
                    </div>

                    <h3 v-else class="txt">{{ message.title }}</h3>

                    <div class="txt_wrap" v-if="!message.from_admin">
                      <div class="mini_text">{{ message.deparment }}</div>
                    </div>

                    <div
                      class="txt_wrap"
                      v-if="message.from_user && !message.from_admin"
                    >
                      <div class="mini_text">
                        {{ message.from_user.last_name
                        }}{{ message.from_user.first_name }}
                      </div>
                    </div>
                    <img
                      v-else
                      src="https://www.kdfc.com/wp-content/themes/uscradio/dist/images/spinner-1.gif"
                      width="25"
                      height="25"
                      style="margin-right: 10px"
                    />

                    <div class="txt_wrap" v-if="message.from_admin">
                      <div class="mini_text">{{ message.from_admin }}</div>
                    </div>
                    <div class="txt_wrap">
                      <div class="board_time">
                        <img
                          src="@/assets/front_component/images/assets/board01.png"
                          alt="時間"
                        />
                        <time
                          class="en"
                          :datetime="formatDate_time(message.published_at)"
                          >{{ formatDate(message.published_at) }}
                        </time>
                      </div>
                    </div>
                  </a>
                  <a @click="setReadAt(message)" style="cursor: pointer" v-else>
                    <div class="icon_wrap">
                      <img
                        :src="iconPath"
                        :alt="messagetype.title_jp"
                        class="mouse_out"
                      />
                      <img
                        :src="iconPath02"
                        :alt="messagetype.title_jp"
                        class="mouse_over"
                      />
                    </div>

                    <h3 v-if="message_type == 'thanks'" class="txt">
                      <span
                        v-for="userlist in message.target_user"
                        :key="userlist.id"
                        >{{ userlist.last_name }}さん</span
                      >
                      <span v-if="message.target_user != ''"
                        >への{{ message.title }}</span
                      >
                    </h3>

                    <h3 v-else-if="message.type == 'idea'" class="txt">
                      {{ message.content }}
                    </h3>

                    <h3
                      v-else-if="
                        message.type == 'challenge' || message.type == 'sdgs'
                      "
                      class="txt"
                    >
                      <!-- {{ message.option }} -->
                      {{ showYear(message.option) }}
                    </h3>

                    <div
                      v-else-if="message.type == 'daily'"
                      class="target_in_ttl_wrap"
                    >
                      <h3 class="txt">{{ message.content }}</h3>
                      <div v-if="message.target_user" class="target_user_wrap">
                        <span>宛先：</span>
                        <img
                          v-if="message.target_user.length <= 0"
                          src="https://www.kdfc.com/wp-content/themes/uscradio/dist/images/spinner-1.gif"
                          width="25"
                          height="25"
                          style="margin-right: 10px"
                        />
                        <span
                          class="target_user_name"
                          v-for="user in message.target_user"
                          :key="user.id"
                          >{{ user.last_name + " " + user.first_name }}</span
                        >
                      </div>
                    </div>

                    <h3 v-else class="txt">{{ message.title }}</h3>

                    <div
                      class="txt_wrap"
                      v-if="
                        message.type != 'award' &&
                        !message.from_admin &&
                        message.from_user &&
                        message.deparment
                      "
                    >
                      <div class="mini_text">
                        {{ message.deparment }}
                      </div>
                    </div>

                    <div
                      class="txt_wrap"
                      v-if="
                        message.type != 'award' &&
                        message.from_user &&
                        !message.from_admin
                      "
                    >
                      <div class="mini_text">
                        {{ message.from_user.last_name
                        }}{{ message.from_user.first_name }}
                      </div>
                    </div>
                    <img
                      v-if="!message.from_user && message.type != 'award'"
                      src="https://www.kdfc.com/wp-content/themes/uscradio/dist/images/spinner-1.gif"
                      width="25"
                      height="25"
                      style="margin-right: 10px"
                      class="muthatl"
                    />
                    <div
                      class="txt_wrap"
                      v-if="message.type == 'award' || message.from_admin"
                    ></div>

                    <div
                      class="txt_wrap"
                      v-if="message.type != 'award' && message.from_admin"
                    >
                      <div class="mini_text">{{ message.from_admin }}</div>
                    </div>

                    <div class="txt_wrap" v-if="message.type == 'award'">
                      <div class="mini_text">めぐる君</div>
                    </div>

                    <div class="txt_wrap">
                      <div class="board_time">
                        <img
                          src="@/assets/front_component/images/assets/board01.png"
                          alt="時間"
                        />
                        <time
                          class="en"
                          :datetime="formatDate_time(message.published_at)"
                          >{{ formatDate(message.published_at) }}</time
                        >
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
              <div v-else class="p_t50">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
              <div id="load" style="display: none">
                <div class="half-circle-spinner">
                  <div class="circle circle-1"></div>
                  <div class="circle circle-2"></div>
                </div>
              </div>
              <div
                id="more_btn"
                class="t_c"
                v-if="5 < total && now_post_num < total"
              >
                <div class="btn_wide">
                  <button v-on:click="messageListmore">さらに読み込む</button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="deco_wrap01"
            v-if="message_type !== 'column' && message_type !== 'daily'"
          >
            <img
              src="@/assets/front_component/images/assets/bard01.png"
              alt="青鳥"
            />
          </div>
          <div
            class="deco_wrap02"
            v-if="message_type !== 'column' && message_type !== 'daily'"
          >
            <img
              src="@/assets/front_component/images/assets/bard02.png"
              alt="緑鳥"
            />
          </div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/kusa02.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/kusa05.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap09">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap10">
            <img
              src="@/assets/front_component/images/assets/kusa07.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap11">
            <img
              src="@/assets/front_component/images/assets/kusa06.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap12">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap13">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap14">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap15">
            <img
              src="@/assets/front_component/images/assets/kusa04.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap16">
            <img
              src="@/assets/front_component/images/assets/kusa03.png"
              alt="草"
            />
          </div>
          <div class="deco_wrap17">
            <img
              src="@/assets/front_component/images/assets/kusa01.png"
              alt="草"
            />
          </div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01">
            <img
              src="@/assets/front_component/images/assets/kikyu01.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap02">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap03">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap04">
            <img
              src="@/assets/front_component/images/assets/heri01.png"
              alt="ヘリコプター"
            />
          </div>
          <div class="deco_wrap05">
            <img
              src="@/assets/front_component/images/assets/kikyu02.png"
              alt="気球"
            />
          </div>
          <div class="deco_wrap06">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap07">
            <img
              src="@/assets/front_component/images/assets/hana01.png"
              alt="花"
              class="hana01"
            />
            <img
              src="@/assets/front_component/images/assets/hana02.png"
              alt="花"
              class="hana02"
            />
            <img
              src="@/assets/front_component/images/assets/hana03.png"
              alt="花"
              class="hana03"
            />
          </div>
          <div class="deco_wrap08">
            <img
              src="@/assets/front_component/images/assets/kikyu03.png"
              alt="気球"
            />
          </div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img
        src="@/assets/front_component/images/assets/menu01.png"
        alt="メニュー"
      />
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
        <li style="display: none">
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'news' } }"
            >お知らせ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'thanks' } }"
            >サンクス&ホメロンカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'idea' } }"
            >アイディアカード</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'daily' } }"
            >気づき日報</router-link
          >
        </li>
        <li>
          <router-link
            :to="{
              name: 'Control messagecreate',
              params: { type: 'photolog' },
            }"
            >フォトログ</router-link
          >
        </li>
        <li>
          <router-link
            :to="{ name: 'Control messagecreate', params: { type: 'column' } }"
            >コラム</router-link
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import message from "@/apis/Message";
import dayjs from "dayjs";
import MessageType from "@/const/MessageType.json";
import $ from "jquery";
import user from "@/apis/User";
import striptags from "striptags";
import Notification from "@/apis/Notification";

import "@/assets/front_component/js/functions.js";

export default {
  beforeRouteEnter: (to, from, next) => {
    console.log(to.params.type);
    if (
      to.params.type === "news" ||
      to.params.type === "daily" ||
      to.params.type === "board" ||
      to.params.type === "event" ||
      to.params.type === "column" ||
      // to.params.type === "thanks" ||
      to.params.type === "award" ||
      to.params.type === "idea" ||
      to.params.type === "sdgs" ||
      to.params.type === "challenge" ||
      to.params.type === "お知らせ"
    ) {
      next();
    } else {
      next({ path: "/404" });
    }
  },
  name: "message",
  data() {
    return {
      iconPath: "",
      iconPath02: "",
      total: "",
      now_post_num: 25, // 表示されている数
      get_post_num: 25, // 取得する数
      messages: [],
      MessageType() {
        return MessageType;
      },
      loader: false,
      group_executive_user: null,
      group_ceo_user: null,
      period_start: null,
      period_end: null,
      group_member_user: [],
      daily_group_list: [],
      more_load: false,

      user_group_id: "",
    };
  },
  components: {},
  watch: {
    $route(to, from) {
      if (from.name == "Front archive" && to.name == "Front archive") {
        $(function () {
          $("#js-loader").show();
          $(window).scrollTop(0);
        });
        this.message_type = this.$route.params.type;
        this.messageList(
          this.message_type,
          this.get_post_num,
          null,
          null,
          null,
          null,
          null
        );
        this.messagetype = MessageType[this.message_type];
        this.iconPath = require("@/assets/front_component/images/assets/" +
          this.messagetype.imgPath);
        this.iconPath02 = require("@/assets/front_component/images/assets/" +
          this.messagetype.imgPath02);
        $(function () {
          $("#js-loader").delay(500).fadeOut(600);
        });
      }
    },
  },
  beforeCreate() {
    // if(this.$route.params.type == 'thanks'){
    //   this.$router.push({ name: 'Front 404' });
    // }
    $(function () {
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $(".g_header_list").removeClass("open");
      $("#button").removeClass("active");
      $(document.body).removeClass("open");
      var result = $("body").attr("style");
      var int_data = result.replace(/[^0-9]/g, "");
      $("html,body").css({
        overflow: "auto",
        height: "auto",
      });
      //bodyfixedを解除する
      $("body").css({
        position: "",
        width: "",
        top: "",
      });
      $(window).scrollTop(int_data);
      $(".g_header_list  > li.has_child").removeClass("open");
      $(".g_header_list  > li.has_child").find(".menu_outwrap").slideUp();
    });
    $(function () {
      $("#js-loader").show();
      $(window).scrollTop(0);
    });
  },
  created() {
    this.get_post_num = this.$route.query.row_count
      ? parseInt(this.$route.query.row_count)
      : 25;

    this.message_type = this.$route.params.type;
    this.messagetype = MessageType[this.message_type];
    this.iconPath = require("@/assets/front_component/images/assets/" +
      this.messagetype.imgPath);
    this.iconPath02 = require("@/assets/front_component/images/assets/" +
      this.messagetype.imgPath02);
    if (this.message_type == "column") {
      this.getGruopUser();
    } else if (this.message_type == "daily") {
      this.getGruopList();
      this.dailyView();
    } else {
      this.messageList(
        this.message_type,
        this.get_post_num,
        null,
        null,
        null,
        null,
        null
      );
    }
  },
  methods: {
    dailyView() {
      console.log(this.$User);
      if (this.$User.group) {
        if (this.$User.group.parent_id == null) {
          this.user_group_id = this.$User.group.id;
        } else {
          this.user_group_id = this.$User.group.parent_id;
        }
        this.messageList(
          this.message_type,
          this.get_post_num,
          null,
          this.user_group_id,
          null,
          null,
          null
        );
      } else {
        this.messageList(
          this.message_type,
          this.get_post_num,
          null,
          null,
          null,
          null,
          null
        );
      }
    },
    getGruopUser() {
      var target_group_user = [];
      user
        .search_group("経営企画室")
        .then((response) => {
          if (response != null) {
            var target_group_id = response.data.data[0].id;
            user
              .get_group(target_group_id)
              .then((response) => {
                console.log("response");
                console.log(response);
                if (response.data.data.group_users.length > 0) {
                  response.data.data.group_users.forEach((user) => {
                    if (user.role == "代表取締役社長") {
                      this.group_ceo_user = user.user_id;
                    } else {
                      target_group_user.push(user.user_id);
                    }
                  });
                  this.group_executive_user = target_group_user;
                  this.messageList(
                    this.message_type,
                    this.get_post_num,
                    null,
                    null,
                    null
                  );
                  console.log(target_group_user);
                }
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                this.loader = false;
              });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });

      user
        .group_list()
        .then((response) => {
          if (response.data.data.length > 0) {
            console.log(response.data.data.length);
            response.data.data.forEach((element) => {
              if (element.name != "経営企画室") {
                this.group_member_user.push(element.id);
              }
            });
            // this.group_member_user = response.data.data.filter(element => !(element != '経営企画室'));
            console.log(this.group_member_user);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    getGruopList() {
      user
        .group_list()
        .then((response) => {
          if (response.data.data.length > 0) {
            console.log(response.data.data.length);
            response.data.data.forEach((element, i) => {
              if (element.parent_id == null && element.name != "開発グループ") {
                this.daily_group_list.push(element);
              }
              if (
                this.message_type == "daily" &&
                response.data.data.length == i + 1
              ) {
                var group_id = this.user_group_id;
                $(function () {
                  $("#departmentSelect option[value='" + group_id + "']").prop(
                    "selected",
                    true
                  );
                });
              }
            });
            
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loader = false;
        });
    },
    ColumnListChange(value) {
      console.log(value);
      this.group_name = value;
      this.messages = [];
      if (this.group_name == "all") {
        this.messageList(
          this.message_type,
          this.get_post_num,
          null,
          null,
          null,
          null,
          null
        );
      } else if (this.group_name == "ceo") {
        this.messageList(
          this.message_type,
          this.get_post_num,
          this.group_ceo_user,
          null,
          null,
          null,
          null
        );
      } else if (this.group_name == "officer") {
        this.messageList(
          this.message_type,
          this.get_post_num,
          this.group_executive_user,
          null,
          null,
          null,
          null
        );
      } else {
        this.messageList(
          this.message_type,
          this.get_post_num,
          null,
          this.group_member_user,
          null,
          null,
          null
        );
      }
    },

    DailyListChange(value) {
      if (value == "all") {
        this.daily_tag = null;

        if (this.$User.group) {
          if (this.$User.group.parent_id == null) {
            this.user_group_id = this.$User.group.id;
          } else {
            this.user_group_id = this.$User.group.parent_id;
          }
          this.messageList(
            this.message_type,
            this.get_post_num,
            null,
            this.user_group_id,
            null,
            null,
            this.daily_tag
          );
        } else {
          this.messageList(
            this.message_type,
            this.get_post_num,
            null,
            null,
            null,
            null,
            this.daily_tag
          );
        }
      } else {
        message
          .tag_list(value)
          .then((response) => {
            if (response.data.data.length > 0) {
              this.daily_tag = response.data.data[0].id;
              this.messages = [];

              if (this.$User.group) {
                if (this.$User.group.parent_id == null) {
                  this.user_group_id = this.$User.group.id;
                } else {
                  this.user_group_id = this.$User.group.parent_id;
                }
                this.messageList(
                  this.message_type,
                  this.get_post_num,
                  null,
                  this.user_group_id,
                  null,
                  null,
                  this.daily_tag
                );
              } else {
                this.messageList(
                  this.message_type,
                  this.get_post_num,
                  null,
                  null,
                  null,
                  null,
                  this.daily_tag
                );
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },
    onPeriodChange: function (e) {
      // 本日を作成.
      var startDate = new Date();
      // 日付に1を設定します.
      startDate.setDate(1);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      // 結果確認.
      startDate = startDate.toISOString().substr(0, 19);
      this.period_start = startDate;
      console.log(this.period_start); // Mon Jul 01 2019 14:42:19 GMT+0900 (日本標準時)

      // 本日を作成.
      var endDate = new Date();
      // 1ヶ月加えて翌月にします.
      endDate.setMonth(endDate.getMonth() + 1);
      // 日付に1を設定し、0時にします.
      endDate.setDate(1);
      endDate.setHours(0);
      endDate.setMinutes(0);
      endDate.setSeconds(0);
      endDate = endDate.toISOString().substr(0, 19);
      // 結果確認.
      this.period_end = endDate;
      console.log(this.period_end); // Mon Jul 01 2019 14:42:19 GMT+0900 (日本標準時)

      this.group_name = e.target.value;
      if (this.group_name == "すべての表彰") {
        this.messageList(
          this.message_type,
          this.get_post_num,
          null,
          null,
          null,
          null,
          null
        );
        this.period_start = null;
        this.period_end = null;
      } else if (this.group_name == "今月の表彰") {
        this.messageList(
          this.message_type,
          this.get_post_num,
          null,
          null,
          this.period_start,
          this.period_end,
          null
        );
      } else {
        this.messageList(
          this.message_type,
          this.get_post_num,
          null,
          null,
          null,
          null,
          null
        );
        this.period_start = null;
        this.period_end = null;
      }
    },
    onDepartmentChange: function (e) {
      this.group_name = e.target.value;
      if (this.group_name == "すべての部署") {
        this.messageList(
          this.message_type,
          this.get_post_num,
          null,
          null,
          null,
          null,
          null
        );
      } else {
        this.messageList(
          this.message_type,
          this.get_post_num,
          null,
          this.group_name,
          null,
          null,
          null
        );
      }
    },
    formatDate: (dateStr) => dayjs(dateStr).format("YYYY.MM.DD"),
    formatDate_time: (dateStr) => dayjs(dateStr).format("YYYY-MM-DD"),
    messageList(type, num, userid, group, start, end, tag) {
      this.loader = true;
      message
        .listNumMessage(type, num, userid, group, start, end, tag)
        .then(
          (response) => {
            if (response != null) {
              this.messages = response.data.data;
              this.total = response.data.total;
              this.messages.forEach((message_single) => {
                message_single.group = message_single.deparment;
                message_single.content = striptags(message_single.content);
                message
                  .showmetakey(message_single.id, "key", "group")
                  .then((response) => {
                    if (response.data.data.length > 0) {
                      message_single.group = response.data.data[0].value;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
                if (message_single.from_id != null) {
                  user
                    .show(message_single.from_id)
                    .then((response) => {
                      if (response != null) {
                        message_single.from_user = response.data.data;
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {});
                }
                message
                  .showmetakey(message_single.id, "key", "from_admin")
                  .then((response) => {
                    if (response.data.data.length > 0) {
                      message_single.from_admin = response.data.data[0].value;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
                if (message_single.target_ids != null) {
                  message_single.target_user = [];
                  message_single.target_ids.forEach((user_id) => {
                    user
                      .show(user_id)
                      .then((response) => {
                        if (response != null) {
                          message_single.target_user.push(response.data.data);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally(() => {});
                  });
                }
                if (type == "daily") {
                  // elavarasan
                  message
                    .notifications_id(message_single.id)
                    .then((response) => {

                      if (response != null) {

                        if (response.data.data != null) {

                          if (response.data.data.id != null) {
                            
                            message
                              .notifications_read(response.data.data.id)
                              .then((response) => {
                                if (response != null) {
                                  message_single.read_at =
                                    response.data.data.read_at;
                                }
                              });
                          }
                        } else {
                          message_single.read_at = true;
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {});
                }
                if (type == "challenge" || type == "sdgs") {
                  message_single.option = "";
                  var contentSplit = JSON.parse(message_single.content);
                  var TermoOption = contentSplit;
                  message_single.option = TermoOption["option"];
                }
                message_single.content = message_single.content.replace(
                  /&nbsp;/g,
                  ""
                );
              });
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            //this.$router.push({ name: 'Front Error' });
          }
        )
        .catch((error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error Catch' });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    messageListmore() {
      $("#load").show();
      $("#more_btn").hide();

      this.now_post_num = this.now_post_num + this.get_post_num;

      if (this.message_type == "column") {
        switch (this.group_name) {
          case "all":
            this.group_executive_user_array = null;
            this.group = null;
            this.period_start = null;
            this.period_end = null;
            break;
          case "ceo":
            (this.group_executive_user_array = this.group_ceo_user),
              (this.group = null);
            this.period_start = null;
            this.period_end = null;
            break;
          case "officer":
            (this.group_executive_user_array = this.group_executive_user),
              (this.group = null);
            this.period_start = null;
            this.period_end = null;
            break;
          default:
            (this.group_executive_user_array = null),
              (this.group = this.group_member_user);
            this.period_start = null;
            this.period_end = null;
            break;
        }
      }
      if (this.message_type == "daily") {
        this.group = this.user_group_id;
      }

      message
        .listNumMessage(
          this.message_type,
          this.now_post_num,
          this.group_executive_user_array,
          this.group,
          this.period_start,
          this.period_end
        )
        .then(
          (response) => {
            this.more_load = true;
            if (response != null) {
              this.messages = response.data.data;
              this.messages.forEach((message_single) => {
                message_single.group = message_single.deparment;
                message_single.content = striptags(message_single.content);
                // if (this.message_type == "news") {
                message
                  .showmetakey(message_single.id, "key", "group")
                  .then((response) => {
                    if (response != null) {
                      message_single.group = response.data.data[0].value;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});

                if (message_single.from_id != null) {
                  user
                    .show(message_single.from_id)
                    .then((response) => {
                      if (response != null) {
                        message_single.from_user = response.data.data;
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {});
                }
                // }
                message
                  .showmetakey(message_single.id, "key", "from_admin")
                  .then((response) => {
                    if (response.data.data.length > 0) {
                      message_single.from_admin = response.data.data[0].value;
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {});
                if (message_single.target_ids != null) {
                  message_single.target_user = [];
                  message_single.target_ids.forEach((user_id) => {
                    user
                      .show(user_id)
                      .then((response) => {
                        if (response != null) {
                          message_single.target_user.push(response.data.data);
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      })
                      .finally(() => {});
                  });
                }
                if (this.message_type == "daily") {
                  message
                    .notifications_id(message_single.id)
                    .then((response) => {
                      if (response != null) {
                        if (response.data.data != null) {
                          if (response.data.data.id != null) {
                            message
                              .notifications_read(response.data.data.id)
                              .then((response) => {
                                if (response != null) {
                                  message_single.read_at =
                                    response.data.data.read_at;
                                }
                              });
                          }
                        } else {
                          message_single.read_at = true;
                        }
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    })
                    .finally(() => {});
                }
                if (
                  this.message_type == "challenge" ||
                  this.message_type == "sdgs"
                ) {
                  message_single.option = "";
                  if (message_single.content.indexOf("{") != -1) {
                    var contentSplit = JSON.parse(message_single.content);
                    var TermoOption = contentSplit;
                    message_single.option = TermoOption["option"];
                  } else {
                    message_single.option = null;
                  }
                }
                message_single.content = message_single.content.replace(
                  /&nbsp;/g,
                  ""
                );
              });
            }
          },
          (error) => {
            console.log(error);
            console.log(error.response.data.errors);
            this.$router.push({ name: "Front Error" });
          }
        )
        .catch((error) => {
          console.log(error);
          this.$router.push({ name: "Front Error Catch" });
        })
        .finally(() => {
          this.loader = false;
        });
    },
    setReadAt(message) {
      if (message.target_ids != "") {
        message.target_ids.forEach((element) => {
          if (element == this.$User.id) {
            Notification.getNotificationByMessageId(message.id).then(
              (response) => {
                if (response != null) {
                  Notification.notificationRead(response.data.data.id);
                }
              }
            );
          }
        });
      }
      this.$router.push({
        name: "Front show",
        params: { type: message.type, id: message.id },
        query: { row_count: this.now_post_num },
      });
    },
    showYear(year) {
      if (typeof year == "number") {
        year = year.toString();
      }

      let setYear = "";
      switch (year) {
        case "2022":
          setYear = "49期 (2022)";
          break;
        case "2023":
          setYear = "50期 (2023)";
          break;
        case "2024":
          setYear = "51期 (2024)";
          break;
        case "2025":
          setYear = "52期 (2025)";
          break;
        case "2026":
          setYear = "53期 (2026)";
          break;
        default:
          setYear = year;
          break;
      }

      return setYear;
    },
  },
  mounted() {
    document.body.className = "page_board-archive";
    this.$nextTick(function () {
      $(function () {
        $(".menu_icon").click(function () {
          if ($(".menu_list_wrap").hasClass("show")) {
            $(".menu_list_wrap").removeClass("show");
          } else {
            $(".menu_list_wrap").addClass("show");
          }
        });
      });
      $(document).click(function (event) {
        if (!$(event.target).closest(".menu_wrap").length) {
          $(".menu_list_wrap").removeClass("show");
        }
      });
      $(function () {
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if (scroll > pagetop_show && scroll > 60) {
            $(".pagetop_wrap").addClass("show");
          } else {
            $(".pagetop_wrap").removeClass("show");
          }
        });
      });
      $(function () {
        $("#js-loader").delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
    });
  },
  updated() {
    if (this.more_load == true) {
      $("#load").hide();
      $("#more_btn").show();
      this.more_load = false;
    }
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
